<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Connect Talent To Project</v-card-title>
          <v-card-text>
            <v-col cols="3">
              <v-autocomplete
                  v-model="projectId"
                  :items="projectData"
                  item-text="name"
                  item-value="id"
                  label="Project *"
                  hint="Select the Project to connect Talent to."
                  @change="talentIdArray = []"
                  autocomplete="off"
              ></v-autocomplete>
            </v-col>
            <v-col cols="7">
              <v-autocomplete
                  v-model="talentIdArray"
                  :items="talentData"
                  item-text="email"
                  item-value="id"
                  label="Talent *"
                  multiple
                  chips
                  hint="Select all talent that should be associated to this Project"
                  autocomplete="off"
                  persistent-hint
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-btn :disabled="projectId === null" @click="putData" color="primary">Save</v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "ConnectTalentToProjectPage",

  mounted() {
    this.getTalent();
    this.getProject()
  },

  computed: {

  },

  data() {
    return {
      name: "",
      description: "",
      loading: false,
      talentData: [],
      projectData: [],
      projectId: null,
      talentIdArray: []
    }
  },

  methods: {
    getTalent() {
      let self = this
      axios.get(`${this.$store.state.apiUrl}/api/talent`, this.$store.state.headers)
          .then(function (response) {
            self.talentData = response.data
          })
    },

    getProject() {
      let self = this
      axios.get(`${this.$store.state.apiUrl}/api/projects`, this.$store.state.headers)
          .then(function (response) {
            self.projectData = response.data
          })
    },

    putData() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.put(`${this.$store.state.apiUrl}/api/projects/${self.projectId}`, {talent: self.talentIdArray}, this.$store.state.headers)
            .then(function () {
              self.$store.commit("displaySnackbar", {
                message: "Added Talent To Project Successfully",
                color: "info"
              })
            })
            .catch(function (response) {
              alert(response.response.data.errors)
            })
      });
    },
  }
}
</script>

<style scoped>

</style>
